import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Box, Chip, Link, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { CalendarIcon, KpiIcon } from '../../../config/svg/CommonSvgIcon';
import '../../../styles/pages/user-goals/user-goals-listing.scss';
import '../../../styles/pages/user-goals/teams-goals-card.scss';
import { AlertInfoIcon } from '../../../config/svg/CommonSvg';
import {
	getSelfReviewList,
	saveGoalReviewSavedApi,
	setPerfomanceGoalAttachmentCount,
} from '../../../action/adminSettings';
import {
	checkUnSavePopUpVisible,
	formatFeedbackDate,
	getMonthDateYearTime,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../config/enums';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import YetToBeginImage from '../../../images/assessment-yet-to-begin.svg';
import AlertDialog from '../../Common/Dialog';
import { setPerformanceGoalForRedirection } from '../../../action/common';
import { useSnackbar } from 'notistack';
import { ChangeStatusIcon, ManagerAssessmentIcon, SelfAssessmentIcon } from '../../../config/svg/AssessmentSvg';
import { ReviewGoalListing } from '../ReviewGoalListing';
import { TeamFinalRatingTabs } from '../TeamFinalRatingTabs';

export const SelfReview: React.FC<any> = (props) => {
	const { drUserData, fromTeamDetails, isDrSelfReviewData } = props;
	const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const { t } = useTranslation();
	const loggedInUserDetail = getUserDetails();

	const [categorySettingsOpen, setCategorySettingsOpen] = useState<any>({ open: false });
	const [currentTab, setCurrentTab] = useState<string>('');
	const dispatch = useDispatch();
	const [performanceGoalsList, setPerformanceGoalsList] = useState<any>({});
	const [expandedGoalList, setExpandedGoalList] = useState<number[]>([]);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, isDraft: false, status: 0 });
	const { redirectedPerformanceGoalDetails } = useSelector((state: any) => state.commonReducer);
	const { enqueueSnackbar } = useSnackbar();
	const ref = useRef<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const userDetails = getUserDetails();
	const { currentGoalReviewCycle } = useSelector((state: any) => state.adminSettingReducer);

	const minDateValue = new Date();
	minDateValue.setFullYear(minDateValue.getFullYear() - 20);
	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 20);
	useEffect(() => {
		if (redirectedPerformanceGoalDetails) {
			setTimeout(() => {
				dispatch(setPerformanceGoalForRedirection(null));
			}, 10000);
		}
		setPerformanceGoalsList({});
		setIsDataChanged(false);
	}, []);
	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDataChanged]);
	useEffect(() => {
		if (currentGoalReviewCycle && currentGoalReviewCycle?.performanceGoalReviewCycleId) {
			getUserPerformanceGoalsList();
		}
	}, [currentGoalReviewCycle]);
	const handleCheckUnSaveData = (event: any) => {
		// if (ref3?.current?.contains(event?.target)) {
		// 	//
		// } else {
		const id = document.getElementById('createLinkPopupDetail');
		const id2 = document.getElementById('back-to-main');
		const class1 = document.getElementsByClassName('ck-body-wrapper');
		if (
			ref.current &&
			checkUnSavePopUpVisible(event) &&
			isDataChanged &&
			((!ref.current?.contains(event.target) &&
				!id?.contains(event.target) &&
				!(class1.length > 0 && class1[0].contains(event.target))) ||
				id2?.contains(event.target))
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
				});
			}
		} else {
		}
		// }
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsDataChanged(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
				// resetTabDetails(false);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	useEffect(() => {
		//notification redirection for conversation
		if (redirectedPerformanceGoalDetails && performanceGoalsList) {
			const { tabId, cycleId, employeeId, categoryId, conversationId, conversationsReplyId } =
				redirectedPerformanceGoalDetails || {};
			if (categoryId) {
				const performanceGoalsCategory = performanceGoalsList?.goalListingFormCategories?.find(
					(item: any) => item.performanceGoalLinkedFormCategoryId === categoryId
				);
				if (performanceGoalsCategory) {
					handleSettingsDrawerOpen(null, performanceGoalsCategory, 'conversation');
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectedPerformanceGoalDetails, performanceGoalsList]);

	const handleSettingsDrawerOpen = (event: any, performanceGoalSetListingFormDetails: any, tabToOpen?: string) => {
		setCategorySettingsOpen({ open: true, data: performanceGoalSetListingFormDetails });
		dispatch(setPerfomanceGoalAttachmentCount(0));
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};

	const getUserPerformanceGoalsList = async () => {
		try {
			setLoader(true);
			let performanceGoalCycleId =
				currentGoalReviewCycle && currentGoalReviewCycle.performanceGoalReviewCycleId
					? currentGoalReviewCycle.performanceGoalReviewCycleId
					: 0;
			let requestParam: any = `performanceGoalCycleReviewId=${performanceGoalCycleId}&employeeId=${drUserData?.employeeId}`;
			let requestParamManager: any = `performanceGoalCycleReviewId=${performanceGoalCycleId}&employeeId=${drUserData?.employeeId}&isManagerView=true&managerId=${loggedInUserDetail.employeeId}`;

			const response: any = await dispatch(getSelfReviewList(fromTeamDetails ? requestParamManager : requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setPerformanceGoalsList(response?.data.entity);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {}
	};

	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1 && openDeleteDialog.status !== 3) {
			saveUserGoalClick(openDeleteDialog.isDraft, openDeleteDialog.status);
		}
		if (type === 1 && openDeleteDialog.status === 3) {
			clearForm();
		}
		setOpenDeleteDialog({ open: false, isDraft: false, status: 0 });
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.scrollHeight;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				//window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};
	const validateForm = (isDraft = false, status = 0) => {
		let postGoalData: any[] = [];
		let formError: boolean = false;
		let expandListCopy = [...expandedGoalList];
		let goalCopy = { ...performanceGoalsList };
		let scrollToId = '';
		goalCopy.goalListingFormCategories.map((catData: any) => {
			catData.performanceGoalSetListingFormDetails.map((goalData: any) => {
				if (
					!goalData.comment ||
					goalData.comment.trim() === '' ||
					goalData.comment.trim() === '<p></p>' ||
					goalData.comment.trim() === '<p><br></p>'
				) {
					if (!isDraft) {
						goalData.isError = true;
						formError = true;
						const elementIndex = expandListCopy.indexOf(catData.performanceGoalLinkedFormCategoryId);
						if (elementIndex === -1) {
							expandListCopy.push(catData.performanceGoalLinkedFormCategoryId);
						}
						if (scrollToId === '') {
							scrollToId = 'performanceGoalLinkedFormDetailId' + goalData.performanceGoalLinkedFormDetailId;
						}
						goalData.isCommentError = true;
						goalData.isQuestionError = true;
					}
				}
				if (!isDraft && !goalData.score) {
					goalData.isRatingError = true;
					goalData.isQuestionError = true;
					goalData.isError = true;
					formError = true;
				}
				postGoalData.push({
					performanceGoalLinkedFormDetailId: goalData.performanceGoalLinkedFormDetailId,
					performanceGoalSetId: goalData.performanceGoalSetId,
					performanceGoalSetDetailId: goalData.performanceGoalSetDetailId,
					performanceGoalReviewWorkFlowTypeId: catData.performanceGoalLinkedFormCategoryId,
					score: goalData.score ? goalData.score : 0,
					comment: String(goalData.comment),
					description: String(goalData.description),
					status: status,
					isDescriptionChanged: goalData.isDescriptionChanged || false,
					descriptionDate: goalData.descriptionDate ? formatFeedbackDate(new Date(goalData.descriptionDate)) : null,
				});
			});
		});
		if (scrollToId !== '') {
			scrollTo(scrollToId);
		}
		setExpandedGoalList(expandListCopy);
		setPerformanceGoalsList(goalCopy);
		return { formError, postGoalData };
	};
	const saveUserGoalClick = async (isDraft = false, status: number) => {
		try {
			let formData: any = validateForm(isDraft, status);
			//console.log('formData', formData);return false;
			let postGoalData: any[] = formData.postGoalData;
			let formError: boolean = formData.formError;
			const requestParams = {
				performanceGoalRatingAssessmentId: 0,
				performanceGoalReviewCycleId: currentGoalReviewCycle?.performanceGoalReviewCycleId,
				employeeId: fromTeamDetails ? drUserData?.employeeId : userDetails?.employeeId,
				performanceGoalSetId: performanceGoalsList.performanceGoalSetId,
				performanceGoalReviewWorkFlowTypeId: fromTeamDetails ? 2 : 1,
				status: isDraft ? 1 : status,
				performanceGoalRatingAssessmentDetailRequest: postGoalData,
				reportingTo: fromTeamDetails ? loggedInUserDetail.employeeId : 0,
			};
			if (!formError || isDraft) {
				const response: any = await dispatch(saveGoalReviewSavedApi(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					setPerformanceGoalsList(response?.data.entity);
					getUserPerformanceGoalsList();
					enqueueSnackbar(response?.data?.messageList.Ratingassessment, {
						variant: 'success',
						autoHideDuration: 3000,
					});
					window.scroll({ top: 0, behavior: 'smooth' });
				} else {
					window.scroll({ top: 0, behavior: 'smooth' });
				}
				setIsDataChanged(false);
			}
		} catch (error) {}
	};

	const clearForm = () => {
		let goalCopy = { ...performanceGoalsList };
		goalCopy.goalListingFormCategories.map((catData: any) => {
			catData.performanceGoalSetListingFormDetails.map((goalData: any) => {
				goalData.comment = '';
				delete goalData.rating;
				delete goalData.score;
				goalData.isCommentError = false;
				goalData.isQuestionError = false;
				goalData.isError = false;
			});
		});
		setPerformanceGoalsList(goalCopy);
		setIsDataChanged(false);
	};
	return (
		<>
			{loader ? (
				<Box className='loading-center-global'>
					<Typography>Loading...</Typography>
				</Box>
			) : currentGoalReviewCycle === null ? (
				<Box className='welcome-content goals-review-welcome' id='performance-goals-welcome'>
					<Box textAlign='center' className='pad-container'>
						<Typography variant='h2'>{t('goalsReviewYetToBeginTitle')}</Typography>
						<Typography>{t('goalsReviewYetToBeginSubTitle')}</Typography>
						<AnimatedIcon
							className=''
							width='388'
							type='image/svg+xml'
							alt='WelcomeImage'
							imagePath={YetToBeginImage}
						/>
					</Box>
				</Box>
			) : (
				<Box className='goals-review-list-area'>
					{fromTeamDetails ? (
						<Box className='user-goal-section-head' id='user-goal-section-head-id'>
							<Box className='user-goal-section-head-left'>
								<Box className='back-to-main' id='back-to-main'>
									<Typography variant='body2' onClick={() => props.backClicked(false)}>
										<Link id='back-btn'>
											<BackArrow /> {t('back')}
										</Link>
									</Typography>
								</Box>
								<Box className='user-info v-center'>
									<Box className='user-img'>
										{drUserData?.imagePath ? (
											<Avatar
												className='avatar-default'
												alt={`${drUserData?.firstName} ${drUserData?.lastName}`}
												src={drUserData?.imagePath}
											/>
										) : (
											<Avatar className='avatar-default'>
												{getUserName({
													firstName: drUserData?.firstName,
													lastName: drUserData?.lastName,
													fullName: drUserData?.fullname,
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4'>
											{drUserData.firstName} {drUserData.lastName}
										</Typography>
									</Box>
								</Box>
								{performanceGoalsList?.statusName && (
									<Chip
										className={
											performanceGoalsList?.status === Enums.ZERO
												? 'default-grey-chip'
												: performanceGoalsList?.status === Enums.ONE
												? 'draft-chip'
												: performanceGoalsList?.status === Enums.TWO
												? 'lagging-chip'
												: performanceGoalsList?.status === Enums.THREE
												? 'default-grey-chip'
												: performanceGoalsList?.status === Enums.FOUR
												? 'on-track-chip'
												: ''
										}
										label={performanceGoalsList?.statusName}
									/>
								)}
								{performanceGoalsList?.isAlertMessageVisible && (
									<Box className='goals-remaining-days'>
										<AlertInfoIcon />
										<Typography variant='h6'>{performanceGoalsList?.alterMessage}</Typography>
									</Box>
								)}
							</Box>
							<Box className='user-goal-section-head-right'>
								{/*<Tooltip title={t('goalSettingDuration')} arrow>
									<Box className='user-goals-setting-duration'>
										<CalendarIcon />
										<Typography variant='h6'>{`${getMonthDateYearTime(
											performanceGoalsList?.settingDurationStartDate
										)} - ${getMonthDateYearTime(performanceGoalsList?.settingDurationEndDate)}`}</Typography>
									</Box>
								</Tooltip>*/}

								{performanceGoalsList?.goalFormName && (
									<Box className='user-goal-form-name'>
										<KpiIcon />
										<Typography variant='h6'>{performanceGoalsList?.goalFormName}</Typography>
									</Box>
								)}
							</Box>
						</Box>
					) : (
						<Box className='user-goal-section-head'>
							<Box className='user-goal-section-head-left'>
								{performanceGoalsList?.goalListingFormCategories?.length > 0 && (
									<>
										<Typography variant='h2'>{t('selfReviewLevel')}</Typography>
									</>
								)}
							</Box>
							<Box className='user-goal-section-head-right'>
								{performanceGoalsList?.settingDurationStartDate && performanceGoalsList?.settingDurationEndDate && (
									<Tooltip title={t('goalSettingDuration')} arrow>
										<Box className='user-goals-setting-duration'>
											<CalendarIcon />
											<Typography variant='h6'>{`${getMonthDateYearTime(
												performanceGoalsList?.settingDurationStartDate
											)} - ${getMonthDateYearTime(performanceGoalsList?.settingDurationEndDate)}`}</Typography>
										</Box>
									</Tooltip>
								)}
								{performanceGoalsList?.goalFormName && (
									<Box className='user-goal-form-name'>
										<KpiIcon />
										<Typography variant='h6'>{performanceGoalsList?.goalFormName}</Typography>
									</Box>
								)}
							</Box>
						</Box>
					)}
					<Box className='goals-review-stats-area' id='goals-review-stats-area'>
						<Box className='goals-review-stats-box-area'>
							<Box className='self-review-box active'>
								<Box className='goals-review-stats-box'>
									<Box className='box-outer-head'>
										<Box className='box-head'>
											<SelfAssessmentIcon />
											<Box className='box-head-right'>
												{performanceGoalsList?.selfReviewStatus === 0 ? (
													<OKRButton className={`btn-link give-review-btn`} text={t('giveReviewLevel')} icon={''} />
												) : performanceGoalsList?.selfReviewStatus === 1 ? (
													<OKRButton className={`btn-link give-review-btn`} text={t('resumeReviewLabel')} icon={''} />
												) : performanceGoalsList?.selfReviewStatus === 2 ? (
													<Typography variant='h2'>{t('doneLevel')}</Typography>
												) : (
													// <Typography variant='h2'>{performanceGoalsList?.selfReviewScore}</Typography>
													<></>
												)}
												{performanceGoalsList?.selfReviewStatus === Enums.ONE && (
													<Box className='goal-review-status'>
														<Chip
															className={performanceGoalsList?.selfReviewStatus === Enums.ONE ? 'draft-chip' : ''}
															label={performanceGoalsList.selfReviewStatusName}
														/>
													</Box>
												)}
											</Box>
										</Box>
									</Box>
									{/* <Box className='box-setting-des'>
								        <Typography variant='h6' className='font-weight-normal'>
									       {t('performsubText')}
								        </Typography>
							        </Box> */}
									<Box className='box-content'>
										<Typography variant='h5'>{t('selfReviewLevel')}</Typography>
										<Typography variant='h6'>{t('rateOnAchievementsLevel')}</Typography>
									</Box>
								</Box>
								<Box className='goals-review-stats-box'>
									<Box className='box-outer-head'>
										<Box className='box-head'>
											<ManagerAssessmentIcon />
											<Box className='box-head-right'>
												{performanceGoalsList?.selfReviewStatus === 0 ||
												performanceGoalsList?.selfReviewStatus === 1 ? (
													<Typography variant='h2' className='blank-text'>
														--
													</Typography>
												) : performanceGoalsList?.selfReviewStatus === 2 ? (
													<Typography variant='h2'>{t('doneLevel')}</Typography>
												) : (
													<></>
												)}
											</Box>
										</Box>
									</Box>
									{/* <Box className='box-setting-des'>
										<Typography variant='h6' className='font-weight-normal'>
										{t('performsubText')}
										</Typography>
									</Box> */}
									<Box className='box-content'>
										<Typography variant='h5'>{t('managerReviewLevel')}</Typography>
										<Typography variant='h6'>{t('achievementReviewAndDevelopment')}</Typography>
									</Box>
								</Box>
							</Box>
							<Box className='goals-review-stats-box'>
								<Box className='box-outer-head'>
									<Box className='box-head'>
										<ChangeStatusIcon />
										<Box className='box-head-right'>
											<Typography variant='h6' className='font-weight-normal'>
												{t('skipManagerLabel')}
											</Typography>
										</Box>
									</Box>
								</Box>
								{/* <Box className='box-setting-des'>
							<Typography variant='h6' className='font-weight-normal'>
								{t('performsubText')}
							</Typography>
						</Box> */}
								<Box className='box-content'>
									<Typography variant='h5'>{t('reviewStatusLabel')}</Typography>
									<Typography variant='h6'>{t('managerialApprovalStatus')}</Typography>
								</Box>
							</Box>
						</Box>
						{performanceGoalsList?.isAlertMessageVisible ? (
							<Box className='goals-review-stats-notes goal-review-error-notes'>
								<AlertInfoIcon />
								<Typography variant='body2' className='review-days-remaining font-weight-normal'>
									<strong>{performanceGoalsList.alertMessage}</strong>
								</Typography>
								<Typography variant='body2' className='font-weight-normal'>
									{performanceGoalsList?.dateMessage}
								</Typography>
							</Box>
						) : (
							<Box className='goals-review-stats-notes'>
								<Typography variant='body2' className='font-weight-normal'>
									{performanceGoalsList?.dateMessage}
								</Typography>
							</Box>
						)}
					</Box>
					{/* <div id='createLinkPopupDetail' ref={ref}>
						<Box className='user-goals-section-content'>
							{performanceGoalsList?.goalListingFormCategories &&
							performanceGoalsList?.goalListingFormCategories?.length ? (
								performanceGoalsList?.goalListingFormCategories?.map((item: any, catIndex: number) => (
									<Box className='user-goals-section-list-panel'>
										<Box className='user-goals-section-list-panel-inner'>
											<Box className='user-goals-section-name-panel'>
												<List disablePadding>
													<ListItem className='user-goals-col1'>
														<Box className='user-goal-title'>
															<Tooltip arrow title={item.categoryName} classes={{ popper: 'tooltip-layout-auto' }}>
																<Typography variant='h4'>{item.categoryName}</Typography>
															</Tooltip>
														</Box>
														<Box className='user-goals-section-actions'>
															<OKRButton
																className={`btn-blue-chip ${
																	expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1
																		? t('expendLabel').toLowerCase()
																		: t('collapseLabel').toLowerCase()
																}`}
																icon={<RightArrowIcon />}
																text={`${item?.performanceGoalSetListingFormDetails?.length} ${t('goalsLabel')}`}
																id={`goals-toggle-btn`}
																handleClick={(e) => handleExpandGoals(e, item)}
															/>
															<OKRButton
																title={t('categoryWeightage')}
																className='btn-oval-shape'
																text={`${item.weightage}%`}
															/>
															<Box
																className={`user-goals-sec-hvr-actions ${
																	expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1
																		? 'hover-active'
																		: ''
																}`}
															>
																<OKRButton
																	className={`attachments-btn`}
																	icon={<AttachmentIcon />}
																	title={`${t('attachmentsLabel')}`}
																	handleClick={(event: any) => handleSettingsDrawerOpen(event, item, 'attachments')}
																/>
																<OKRButton
																	className={`conversation-btn`}
																	wrapClassName={'conversation-btn-wrap'}
																	icon={
																		<Badge
																			variant='dot'
																			className='dot-bubble'
																			overlap='circular'
																			invisible={!item.isConversationRedDotAlert ? true : false}
																		>
																			<ConversationIcon />
																		</Badge>
																	}
																	title={`${t('conversationLabel')} ${
																		item.isConversationRedDotAlert ? `(${item.conversationRedDotAlert})` : ''
																	}`}
																	handleClick={(event: any) => handleSettingsDrawerOpen(event, item, 'conversation')}
																/>
															</Box>
														</Box>
													</ListItem>
												</List>
											</Box>
											{item?.performanceGoalSetListingFormDetails &&
											item?.performanceGoalSetListingFormDetails.length ? (
												item?.performanceGoalSetListingFormDetails?.map((goalList: any, goalIndex: number) => (
													<Collapse
														in={expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1}
														className='user-goals-list-panel'
													>
														<Box
															className='user-goals-list-panel-inner'
															id={`${'performanceGoalLinkedFormDetailId' + goalList.performanceGoalLinkedFormDetailId}`}
														>
															<Box className='user-goal-name'>
																<Tooltip arrow title={goalList.name} classes={{ popper: 'tooltip-layout-auto' }}>
																	<Typography variant='h4' className='font-weight-normal'>
																		{goalList.name}
																	</Typography>
																</Tooltip>
															</Box>
															{goalList?.description && goalList?.questionType === 2 ? (
																<Box className='user-goal-description-saved'>
																	<Box
																		dangerouslySetInnerHTML={{
																			__html: goalList.description || '',
																		}}
																	></Box>
																</Box>
															) : goalList?.description && goalList?.questionType === 3 ? (
																<Box className='user-goal-num-date-saved'>
																	<Typography>{goalList?.description}</Typography>
																</Box>
															) : goalList?.questionType === 4 ? (
																<Box className={`${goalList.descriptionDate ? 'user-goal-num-date-saved' : ''}`}>
																	{goalList.descriptionDate ? (
																		<Typography>{getMonthDateYearTime(new Date(goalList.descriptionDate))}</Typography>
																	) : (
																		<></>
																	)}
																</Box>
															) : goalList?.questionType === 5 ? (
																<Box
																	className={`${
																		goalList?.description || goalList.descriptionDate ? 'user-goal-num-date-saved' : ''
																	}`}
																>
																	<Typography>{goalList?.description}</Typography>
																	{goalList.descriptionDate ? (
																		<Typography>{getMonthDateYearTime(new Date(goalList.descriptionDate))}</Typography>
																	) : (
																		<></>
																	)}
																</Box>
															) : (
																<></>
															)}

															<Box className='user-goals-actions'>
																<OKRButton
																	title={t('goalWeightage')}
																	className='btn-oval-shape'
																	text={`${goalList.weightage}%`}
																/>
																<Box className='user-goals-hvr-actions'>
																	<OKRButton
																		className={`attachments-btn`}
																		icon={<AttachmentIcon />}
																		title={`${t('attachmentsLabel')}`}
																		handleClick={(event: any) =>
																			handleGoalSettingsDrawerOpen(event, item, 'attachments', goalList)
																		}
																	/>
																	<OKRButton
																		className={`conversation-btn`}
																		icon={<ResponseLogIcon />}
																		title={t('responseLog')}
																		handleClick={(event: any) =>
																			handleGoalSettingsDrawerOpen(event, item, 'conversation', goalList)
																		}
																	/>
																</Box>
															</Box>

															{expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1 && (
																<Box className='goal-review-rating-area'>
																	<StarRating
																		t={t}
																		performanceGoalsList={performanceGoalsList}
																		questionIndex={goalIndex}
																		sectionIndex={catIndex}
																		question={goalList}
																		getLabelText={getLabelText}
																		section={item}
																		isCommentError={goalList?.isCommentError || goalList.isQuestionError}
																		handleChangeStarQuestionValue={(
																			event: any,
																			value: any,
																			question: any,
																			questionIndex: number,
																			section: any,
																			sectionIndex: number
																		) =>
																			handleChangeStarQuestionValue(
																				event,
																				value,
																				question,
																				questionIndex,
																				section,
																				sectionIndex
																			)
																		}
																		handleChangeStarQuestionTextValue={(
																			event: any,
																			value: any,
																			question: any,
																			questionIndex: number,
																			section: any,
																			sectionIndex: number
																		) =>
																			handleChangeStarQuestionTextValue(
																				event,
																				value,
																				question,
																				questionIndex,
																				section,
																				sectionIndex
																			)
																		}
																		handleChangeStarQuestionHoverValue={(
																			event: any,
																			value: any,
																			question: any,
																			questionIndex: number,
																			section: any,
																			sectionIndex: number
																		) => console.log(event, value, question, questionIndex, section, sectionIndex)}
																	/>
																</Box>
															)}
														</Box>
													</Collapse>
												))
											) : (
												<></>
											)}
										</Box>
									</Box>
								))
							) : (
								<></>
							)}
						</Box>

						{performanceGoalsList?.isEditable && performanceGoalsList?.goalListingFormCategories?.length > 0 && (
							<Box className='performance-goals-action'>
								<Box>
									{(performanceGoalsList?.status === 0 || performanceGoalsList?.status === 1) && (
										<OKRButton
											className={'btn-link'}
											disabled={!isDataChanged}
											id='pip_create_submit_form'
											text={t('clearForm')}
											handleClick={() => {
												showSavePopup(false, 3);
											}}
										/>
									)}
								</Box>
								<Box>
									{(performanceGoalsList?.status === 0 || performanceGoalsList?.status === 1) && (
										<>
											<OKRButton
												className={'btn-link'}
												id='pip_create_saveAsDraft_form'
												text={t('saveDraft')}
												handleClick={() => {
													saveUserGoalClick(true, 1);
												}}
											/>
										</>
									)}
									{performanceGoalsList?.status === 0 ? (
										<OKRButton
											className={'btn-primary'}
											id='pip_create_submit_form'
											text={t('submit')}
											handleClick={() => {
												showSavePopup(false, 2);
											}}
										/>
									) : (
										<OKRButton
											className={'btn-primary'}
											id='pip_create_submit_form'
											text={t('submit')}
											handleClick={() => {
												showSavePopup(false, 2);
											}}
										/>
									)}
								</Box>
							</Box>
						)}
						{categorySettingsOpen.open && (
							<CategorySettings
								{...props}
								t={t}
								categorySettingsOpen={categorySettingsOpen}
								handleSettingsDrawerClose={handleSettingsDrawerClose}
								selectTab={currentTab}
								performanceGoalsList={performanceGoalsList}
							/>
						)}
						{goalSettingsOpen.open && (
							<GoalSettings
								{...props}
								t={t}
								goalSettingsOpen={goalSettingsOpen}
								handleGoalSettingsDrawerClose={handleGoalSettingsDrawerClose}
								selectTab={currentTab}
								performanceGoalsList={performanceGoalsList}
							/>
						)}
					</div> */}
					{fromTeamDetails ? (
						isDrSelfReviewData ? (
							<TeamFinalRatingTabs
								drUserData={drUserData}
								fromTeamDetails={fromTeamDetails}
								isDrSelfReviewData={false}
							/>
						) : (
							<ReviewGoalListing drUserData={drUserData} fromTeamDetails={fromTeamDetails} isDrSelfReviewData={false} />
						)
					) : (
						<ReviewGoalListing drUserData={drUserData} fromTeamDetails={fromTeamDetails} isDrSelfReviewData={true} />
					)}
				</Box>
			)}

			{openDeleteDialog.open && (
				<AlertDialog
					module='information'
					message={
						openDeleteDialog.status === 3
							? t('clearSelfReview')
							: openDeleteDialog.status === 2
							? t('selfReviewSubmit')
							: t('selfReviewSubmit')
					}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
