import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import LinearLoader from '../Common/Loader';
import Header from '../../containers/Header';
import '../../styles/pages/goals-review/goalsReview.scss';
import { CycleTab } from './CycleTab';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../config/utils';
import { currentDashboardTab, hidePerformanceCycle } from '../../action/common';
import { UserPerformTabPanel, UserPerformTabs } from '../UserPerformance/UserPerformTabs';
import { SelfReview } from './SelfReview';
import { TeamReview } from './TeamReview';
import { GoalReviewTabPanel, GoalReviewTabs } from './GoalReviewTabs';
import { setPerformanceReviewDrUserData } from '../../action/adminSettings';

export const GoalsReview = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { dashboardCurrentTab } = useSelector((state: any) => state.commonReducer);
	const { performanceSelfReviewDrUserData, performanceReviewDrUserData } = useSelector(
		(state: any) => state.adminSettingReducer
	);

	const [tabSelected, setTabSelected] = useState(0);
	const [loader, setLoader] = useState<boolean>(false);
	const [userData, setUserData] = useState<any>(null);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	useEffect(() => {
		dispatch(hidePerformanceCycle(true));
		setUserData(getUserDetails());
	}, []);
	useEffect(() => {
		if (dashboardCurrentTab !== tabSelected) {
			setTabSelected(dashboardCurrentTab);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardCurrentTab]);

	const handleTabChange = (event: any, newValue: any) => {
		setTabSelected(newValue);
		dispatch(currentDashboardTab(newValue));
	};

	const giveReviewReset = () => {
		dispatch(setPerformanceReviewDrUserData({}));
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='page-subheader' id='page-perform-subheader'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>{t('performanceAssessment')}</Typography>
						</Box>
						<GoalReviewTabs
							tabSelected={tabSelected}
							handleTabChange={handleTabChange}
							{...props}
							userDetails={userData}
						/>
					</Box>
					<Box className='page-subheader-right'>
						<CycleTab {...props} fromReview={true} />
					</Box>
				</Box>
			</Box>
			<Box className='pad-container'>
				<GoalReviewTabPanel value={tabSelected} index={0}>
					<SelfReview {...props} fromTeamDetails={false} drUserData={userData} backClicked={() => {}} />
				</GoalReviewTabPanel>
				<GoalReviewTabPanel value={tabSelected} index={1}>
					{(performanceReviewDrUserData && performanceReviewDrUserData.employeeId) ||
					(performanceSelfReviewDrUserData && performanceSelfReviewDrUserData.employeeId) ? (
						<SelfReview
							{...props}
							fromTeamDetails={true}
							drUserData={
								performanceSelfReviewDrUserData !== null ? performanceSelfReviewDrUserData : performanceReviewDrUserData
							}
							isDrSelfReviewData={performanceSelfReviewDrUserData !== null}
							backClicked={() => {
								giveReviewReset();
							}}
						/>
					) : (
						<TeamReview
							{...props}
							fromTeamDetails={false}
							drUserData={{}}
							backClicked={() => {}}
							fromApproval={false}
						/>
					)}
				</GoalReviewTabPanel>
				{
					/*okrMasterData?.isApprovals &&*/ <GoalReviewTabPanel value={tabSelected} index={2}>
						{(performanceReviewDrUserData && performanceReviewDrUserData.employeeId) ||
						(performanceSelfReviewDrUserData && performanceSelfReviewDrUserData.employeeId) ? (
							<SelfReview
								{...props}
								fromTeamDetails={true}
								drUserData={
									performanceSelfReviewDrUserData !== null
										? performanceSelfReviewDrUserData
										: performanceReviewDrUserData
								}
								isDrSelfReviewData={performanceSelfReviewDrUserData !== null}
								backClicked={() => {
									giveReviewReset();
								}}
							/>
						) : (
							<TeamReview
								{...props}
								fromTeamDetails={false}
								drUserData={{}}
								backClicked={() => {}}
								fromApproval={true}
							/>
						)}
					</GoalReviewTabPanel>
				}
			</Box>
		</>
	);
};
